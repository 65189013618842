import type {
    MetaFunction,
    ActionFunctionArgs,
    LoaderFunctionArgs,
} from "@remix-run/node";
import { Form, useActionData, useNavigation } from "@remix-run/react";
import { Button } from "~/components/button";
import { Field, Label } from "~/components/fieldset";
import { Input } from "~/components/input";
import { Text, TextLink } from "~/components/text";
import { login, requireGuest } from "~/services/auth.server";
import { getRootTitle } from "~/utils/utils.tsx";
import { ValidationError } from "~/components/validationerror.tsx";

export const meta: MetaFunction = ({ matches }) => {
    return [{ title: "Login - " + getRootTitle(matches) }];
};

export let loader = async (args: LoaderFunctionArgs) => {
    await requireGuest(args);

    return null;
};

export const action = async (args: ActionFunctionArgs) => {
    await requireGuest(args);

    let { errors, redirect } = await login(args);

    return errors || redirect;
};

export default function LoginPage() {
    const errors = useActionData<typeof action>();
    const navigation = useNavigation();
    const isSubmitting = navigation.state === "submitting";

    return (
        <>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 rounded-md mt-2 mb-3">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg
                                className="h-5 w-5 text-yellow-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm mb-2 text-yellow-700">
                                <strong>
                                    Welcome to the new Dealer Portal!
                                </strong>
                            </p>
                            <p className="text-sm text-yellow-700">
                                If you have not yet logged into the new system,
                                you will need to first reset your password using
                                the link below.
                            </p>
                        </div>
                    </div>
                </div>

                <Form className="space-y-6" method="POST">
                    <Field>
                        <Label>Email</Label>
                        <Input
                            name="email"
                            type="email"
                            autoComplete="email"
                            tabIndex={1}
                            required
                        />
                        <ValidationError name="email" errors={errors} />
                    </Field>

                    <Field>
                        <div className="flex items-center justify-between mb-3">
                            <Label className="font-medium">Password</Label>
                            <div className="text-sm">
                                <Text>
                                    <TextLink href="/password/reset">
                                        Forgot Password?
                                    </TextLink>
                                </Text>
                            </div>
                        </div>
                        <Input
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            tabIndex={2}
                            required
                        />
                        <ValidationError name="password" errors={errors} />
                    </Field>

                    <div>
                        <Button
                            type="submit"
                            className="flex w-full justify-center"
                            disabled={isSubmitting}
                        >
                            Log In
                        </Button>
                    </div>
                </Form>

                <Text className="mt-10 text-center text-sm text-gray-500">
                    <TextLink href="/register">Register An Account</TextLink>
                </Text>
            </div>
        </>
    );
}
